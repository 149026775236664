import React from "react";
import Layout from "../components/layout";
import ServicesComponent from "../components/Services";

const Services = () => {
  return (
    <Layout pageTitle="Serviços">
      <ServicesComponent isServicesPage></ServicesComponent>
    </Layout>
  );
};

export default Services;
